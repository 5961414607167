import React, { ReactElement, useEffect, useRef, useState } from 'react'
import { useRouter } from 'next/router'
import { Button, FocusTrap, PasswordInput, TextInput } from '@mantine/core'
import { useForm } from '@mantine/form'
import useTranslation from 'next-translate/useTranslation'
import Trans from 'next-translate/Trans'
import { isNil } from 'lodash-es'
import {
  DefaultApplicationDocument,
  MemberInvitationInput,
  MemberInvitationType,
  PrincipalType,
  useGeetestConfigurationLazyQuery,
  useLoginMutation,
} from 'types/domain'
import { getGeetestResult } from 'components/ui/Geetest'
import { useGeetest } from 'hooks'
import { graphqlErrorCatch, isMobile, isMobileOrEmail } from 'utils'
import { AccountComplete, ApplicationDefault, ApplicationSurvey } from 'config/constants'
import { LOGIN_IDENTIFIER } from './constants'
import useAutoFillButtonDisableState from 'modules/Login/hooks/useAutoFillButtonDisableState'
import { XM_SITE_PENDING_APPLY_TPL_CODE, XM_SITE_PENDING_PRODUCT_CODE } from 'config/constants/sessionStorage'
import { initAndCleanUpLoginData } from 'modules/Login/actionsAfterLogin'
import useCachedIdentifier from 'components/business/hooks/useCachedIdentifier'
import { initializeApollo } from 'plugins/apollo'
import { read, remove } from 'utils/cookieUtils'
import { HX_LOGIN_PRE_ROUTE } from 'config/constants/cookie'

type Props = {
  invitation?: MemberInvitationInput
  getSignupLink: (identifier?: string) => ReactElement
  identifier?: string
}

interface FormValues {
  identifier: string
  password: string
}

const Form = ({ invitation, getSignupLink, ...props }: Props) => {
  const { t } = useTranslation('pageLogin')
  const router = useRouter()
  const identifier = props.identifier ?? ''
  const form = useForm<FormValues>({
    initialValues: { identifier: identifier as string, password: '' },
    validate: {
      identifier: validateIdentifier,
      password: validatePassword,
    },
  })
  useCachedIdentifier(form)
  const formValuesRef = useRef<FormValues>(form.values)
  const [loginMutation] = useLoginMutation()
  const [loading, setLoading] = useState(false)
  const [getGeetestConfiguration, geetestConfigurationRes] = useGeetestConfigurationLazyQuery({
    fetchPolicy: 'no-cache',
  })

  const { identifierInputRef, isAutoFill } = useAutoFillButtonDisableState(form.values.identifier)

  function validateIdentifier(value: string) {
    if (!value) {
      return t('errors.identifierRequired')
    }
    if (!isMobileOrEmail(value.trim())) {
      return t('errors.identifierInvalid')
    }
    return null
  }

  function validatePassword(value: string) {
    if (!value) {
      return t('errors.passwordRequired')
    }
    return null
  }

  useEffect(() => {
    getGeetestConfiguration().then()
    // eslint-disable-next-line
  }, [])

  useEffect(() => {
    formValuesRef.current = form.values
  }, [form.values])

  const handleLogin = async (captcha: any) => {
    setLoading(true)
    const { identifier, password } = formValuesRef.current
    try {
      const { data } = await loginMutation({
        variables: {
          input: {
            principal: identifier.trim(),
            principalType: isMobile(identifier) ? PrincipalType.Mobile : PrincipalType.Email,
            credential: password,
            geetestInfo: captcha && getGeetestResult(captcha),
            invitation,
          },
        },
      })
      if (data?.signInUser) {
        const { token, user, invitationAcceptance } = data.signInUser
        initAndCleanUpLoginData(token!, user?.id!)
        if (!user?.accountUser) {
          await router.replace(AccountComplete)
          return
        }

        const loginPreRoute = read(HX_LOGIN_PRE_ROUTE)
        if (!isNil(loginPreRoute)) {
          remove({ name: HX_LOGIN_PRE_ROUTE, domain: process.env.NEXT_PUBLIC_COOKIE_ALL_DOMAIN })
          await router.replace(loginPreRoute)
          return
        }

        const { newlyJoined, invitation: appInvitation } = invitationAcceptance ?? {}
        const pendingApplyTplCode = read(XM_SITE_PENDING_APPLY_TPL_CODE)
        const pendingProductCode = read(XM_SITE_PENDING_PRODUCT_CODE)
        if (pendingApplyTplCode || pendingProductCode) {
          await router.replace(ApplicationDefault)
          return
        }
        const appId = await getApplicationAfterSignIn(appInvitation?.application?.id)
        if (appId) {
          await router.replace(
            { pathname: ApplicationSurvey, query: { appId, newlyJoined } },
            ApplicationSurvey.replace('[appId]', appId)
          )
        } else {
          await router.replace(AccountComplete)
        }
      }
    } catch (err: any) {
      const { graphQLErrors } = err
      handleErrors(graphQLErrors)
      setLoading(false)
    }
  }

  const getApplicationAfterSignIn = async (invitationApplicationId?: string) => {
    if (!isNil(invitationApplicationId)) {
      return invitationApplicationId
    }
    const { data: defaultApplication } = await initializeApollo().query({
      query: DefaultApplicationDocument,
    })
    return defaultApplication?.defaultApplication?.id
  }

  const [captcha, captchaConfiguration] = useGeetest(handleLogin, geetestConfigurationRes)

  const handleSubmit = async () => {
    if (captchaConfiguration?.enabled) {
      captcha?.showBox()
    } else {
      await handleLogin(null)
    }
  }

  function handleErrors(graphQLErrors: any) {
    const graphQLError = graphQLErrors?.[0]
    const extensions = graphQLError?.extensions
    const errorCode = extensions?.code
    switch (errorCode) {
      case 'error.invalid_credentials':
        form.setFieldError('identifier', t('errors.invalidCredential'))
        break
      case 'error.not_registered':
        const identifier = formValuesRef.current.identifier
        sessionStorage.setItem(LOGIN_IDENTIFIER, identifier)
        const signupLink = getSignupLink(identifier)

        const message = isMobile(identifier) ? (
          <Trans i18nKey="errors.notRegistered" components={{ component: signupLink }} />
        ) : (
          t('errors.emailNotfound')
        )
        form.setFieldError('identifier', message)
        break
      default:
        const isAppInviting = invitation?.type === MemberInvitationType.Application
        const mapping = new Map()
        if (isAppInviting) {
          mapping.set('error.mismatched', t('pageAppInvite:errors.accountMismatched'))
        }
        graphqlErrorCatch({
          graphQLErrors,
          mapping,
          defaultMessage: t(isAppInviting ? 'pageAppInvite:errors.default' : 'errors.default', {
            message: graphQLError.message,
          }),
        })
    }
  }

  return (
    <FocusTrap>
      <form onSubmit={form.onSubmit(handleSubmit)}>
        <TextInput
          size="md"
          placeholder={t('form.identifier')}
          data-autofocus
          autoComplete="username"
          ref={identifierInputRef}
          {...form.getInputProps('identifier')}
        />
        <PasswordInput
          size="md"
          placeholder={t('form.password')}
          mt="xl"
          autoComplete="current-password"
          {...form.getInputProps('password')}
        />

        <Button
          size="md"
          type="submit"
          fullWidth
          mt="xl"
          loading={loading}
          disabled={!form.isValid('identifier') && !isAutoFill}
        >
          {t('form.submit')}
        </Button>
      </form>
    </FocusTrap>
  )
}
export default Form
