import { useEffect } from 'react'
import { LOGIN_IDENTIFIER } from 'modules/Login/constants'
import { useMounted } from 'hooks'

const useCachedIdentifier = (form: any) => {
  const mounted = useMounted()

  useEffect(() => {
    if (mounted) {
      form.setValues({ identifier: sessionStorage.getItem(LOGIN_IDENTIFIER) ?? '' })
    }
  }, [mounted])
}

export default useCachedIdentifier
