import React, { ReactElement, useEffect } from 'react'
import getT from 'next-translate/getT'
import useTranslation from 'next-translate/useTranslation'
import { Center, Group, Stack, Text, Title } from '@mantine/core'
import { NextPageWithLayout } from 'types/nextCustomType'
import Form from 'modules/Login/Form'
import { SessionLayout } from 'components/site'
import { AnchorLink, SessionPaper } from 'components/ui'
import { Signup } from 'config/constants/routerPath'
import Trans from 'next-translate/Trans'
import { PasswordReset } from 'config/constants'
import { GetServerSidePropsContext } from 'next'
import { useAccountSourceTrack } from 'hooks'
import { serverSideCheckLogin } from 'utils/serverSideAuthCheck'
import usePersistorClient from 'components/business/hooks/usePersistorClient'
import { XM_DATA } from 'config/constants/localStorage'
import useApollo from 'hooks/useApollo'
import Head from 'next/head'

interface Props {
  referer?: string
}

const Login: NextPageWithLayout<Props> = ({ referer }) => {
  useAccountSourceTrack(referer)
  const { t } = useTranslation('pageLogin')
  const client = useApollo({})

  const { clearCache } = usePersistorClient({ client: client, key: XM_DATA })
  useEffect(() => {
    clearCache()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const getSignupLink = (identifier?: string) => {
    return <AnchorLink href={Signup} />
  }
  return (
    <>
      <Stack align="center" justify="space-between" className="flex-1">
        <Center className="h-full w-full">
          <SessionPaper noBorder minHeight={0} height="auto" p={40}>
            <Stack justify="space-between" spacing="xl">
              <Title order={2} mb="sm">
                {t('welcome')}
              </Title>
              <Form getSignupLink={getSignupLink} />
              <Group position="apart">
                <Text size="sm">
                  <Trans
                    i18nKey="pageLogin:noAccount"
                    components={{
                      component: <AnchorLink href={Signup} />,
                    }}
                  />
                </Text>
                <Text size="sm">
                  <Trans
                    i18nKey="pageLogin:forgotPassword"
                    components={{
                      component: <AnchorLink href={PasswordReset} />,
                    }}
                  />
                </Text>
              </Group>
            </Stack>
          </SessionPaper>
        </Center>
      </Stack>
    </>
  )
}

Login.getLayout = function getLayout(page: ReactElement) {
  return (
    <SessionLayout>
      <Head>
        <title>登录 - 浩客HowXm官网 - 数字化系统必备的应用内调研、评价、通知工具，支持免费试用</title>
      </Head>
      {page}
    </SessionLayout>
  )
}

export async function getServerSideProps(context: GetServerSidePropsContext) {
  const { locale, req } = context
  const t = await getT(locale, 'pageLogin')
  const referer = req?.headers.referer || null
  return (
    serverSideCheckLogin(context) || {
      props: {
        pageTitle: t('pageLogin:title'),
        referer,
      },
    }
  )
}

export default Login
