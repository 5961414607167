import { useEffect, useRef, useState } from 'react'

export default function useAutoFillButtonDisableState(mobile: string) {
  const identifierInputRef = useRef<HTMLInputElement>(null)
  const [isAutoFill, setIsAutoFill] = useState(false)

  useEffect(() => {
    identifierInputRef.current?.blur()
    setTimeout(() => {
      const isAutoFilled = document.querySelectorAll('input:-internal-autofill-selected' as string).length > 0
      if (isAutoFilled) {
        setIsAutoFill(true)
      }
      // 500: if too small，maybe invalid，so as big as possible
    }, 500)
  }, [])

  useEffect(() => {
    if (isAutoFill) {
      setIsAutoFill(false)
    }
    // eslint-disable-next-line
  }, [mobile])

  return {
    identifierInputRef: identifierInputRef,
    isAutoFill,
  }
}
