import appContext from 'service'
import { LOGIN_IDENTIFIER } from 'modules/Login/constants'
import { removeUserSourceTrackingCookies } from 'utils/cookieUtils'

export function initAndCleanUpLoginData(token: string, userId: string) {
  appContext.setSystemToken(token!)
  appContext.setUid(userId)
  sessionStorage.removeItem(LOGIN_IDENTIFIER)
  removeUserSourceTrackingCookies()
}
